import React, { useEffect, useState } from "react";
import axios from "axios"
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import style from "./Contact.module.css";

async function postForm(body) {
    const url = process.env.GATSBY_CONTACT_FORM_ENDPOINT;
    console.log("POST FORM BODY", body)
    const result = await axios.post(url, body, {
            headers: {
                'Accept': 'application/json'
            }
        })
    const res = await result.data;

    return res;
}

const Contact = props => {
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        errors,
        watch,
        formState
    } = useForm({
        mode: "all",
    });
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalShown, setIsModalShown] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        subject: "Subject line",
        content: "Message content"
    })

    useEffect(
        () => {
            if(document.getElementById("SubmitContactForm") && document.getElementById("SubmitContactForm").length) {
                document.getElementById("SubmitContactForm").disabled = true;
            }
        }, [isSubmitting]
    )

    const resetForm = () => {
        setIsModalShown(false)
    }

    const submit = async (data, e) => {
        e.preventDefault()
        setIsSubmitting(true)

        const body = {
            first_name: data.first_name,
            email: data.email,
            organization: data.organization,
            message: data.message,
            timestamp: Date.now(),
        }

        postForm(body)
        .then(function (response) {
            console.log("POST FORM RESPONSE", response)
            setResponseMessage({
                subject: response.message,
                content: "Thank you for contacting us. We will respond shortly."
            })
            setIsModalShown(true)
            setIsSubmitting(false)
            document.getElementById("contactForm").reset();
        })
        .catch(function (error) {
            console.log("FIRST CATCH ERROR", error)
            console.log("FIRST CATCH ERROR", error.response)
            setIsSubmitting(false)
        })

    };

    const handleFileChange = e => {
        console.log("GOT A FILE", e.target.value)
    }

    return (
        <form id="contactForm" className="default-form" onSubmit={(e) => handleSubmit(submit)(e)}>
            <fieldset className="uk-fieldset uk-text-left">
                <div className="uk-margin-small-top">
                    <label htmlFor="first_name" className={`uk-text-small ${errors.first_name ? "uk-text-danger" : "uk-text-muted"}`}>
                    {errors.first_name ?
                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                    :
                    <small className="uk-text-danger uk-display-inline">*</small>} Your name {errors.first_name?.type === "required" && "is required"}
                        <input
                            id="first_name"
                            name="first_name"
                            ref={register({required: true})}
                            type="text"
                            className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                            autoComplete="off"
                            autoCorrect="off"
                            required
                        />
                    </label>
                </div>

                <div className="uk-margin-small-top">
                    <label htmlFor="email" className={`uk-text-small ${errors.email ? "uk-text-danger" : "uk-text-muted"}`}>
                    {errors.email ?
                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                    :
                    <small className="uk-text-danger uk-display-inline">*</small>} Email address {
                        errors.email?.type === "required" && "is required" || errors.email && errors.email.message
                    }
                        <input
                            id="email"
                            name="email"
                            ref={register({
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "is invalid"
                              }
                            })}
                            type="text"
                            className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                            autoComplete="off"
                            autoCorrect="off"
                            required
                        />
                    </label>
                </div>

                <div className="uk-margin-small-top">
                    <label htmlFor="message" className={`uk-text-small ${errors.message ? "uk-text-danger" : "uk-text-muted"}`}>
                    {errors.message ?
                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                    :
                    <small className="uk-text-danger uk-display-inline">*</small>} Your message
                        <textarea
                            id="message"
                            name="message"
                            ref={register({required: true})}
                            rows="3"
                            className={`uk-textarea uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                            maxLength="1000"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </label>
                </div>

                {!isLoading ?

                <div className="uk-text-center uk-margin-large">
                    {!formState.isValid  ?
                        <span style={{color: "#d8d8d8"}} className="uk-text-uppercase uk-text-small">Fill out required fields</span>
                    :
                        isSubmitting ?
                        <span style={{color: "#666"}} className="uk-text-uppercase uk-text-small">Just a moment</span>
                        :
                        <button
                            id="SubmitContactForm"
                            type="submit"
                            className={`uk-button uk-button-default uk-button-large uk-border-pill`}
                            >
                            Send your message
                        </button>
                    }
                </div>
                :
                null
                }
            </fieldset>
            <Modal
                isShown={isModalShown}
                style={{borderStyle: "none"}}>
                <div className="uk-card uk-card-default">
                    <div className="uk-card-header">
                        <h4>{responseMessage.subject}</h4>
                    </div>
                    <div className="uk-card-body">
                        <p>{responseMessage.content}</p>
                        <div className="uk-text-center uk-margin-large">
                            <button
                                className="uk-button uk-button-primary uk-border-pill"
                                onClick={resetForm}>
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </form>
    )

}

export default Contact;
