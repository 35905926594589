import React from "react";

const SA_logo = ({color}) =>
        <svg
            width="503"
            height="123"
            viewBox="0 0 503 123"
            fill="none"
            id={color}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M98.052.012C107.58-.086 115.896 1.015 123 3.314V32.76c-2.447-6.887-10.378-10.206-23.793-9.956-6.007 0-10.744.618-14.21 1.85-3.62 1.467-6.47 3.935-8.55 7.406L63.172 21.928 51.785 33.363c-5.162-3.253-8.128-5.104-13.892-7.224-5.68-1.89-12.217-2.837-19.617-2.837-8.43 0-14.323 1.325-17.68 3.973-.204.153-.403.31-.596.468V2.904C5.555 1.599 11.647.947 18.276.947c6.195 0 11.745.341 16.649 1.022 4.903.756 9.507 1.816 13.808 3.177 5.55 1.983 9.21 4.616 12.992 7.648C70.64 4.008 82.751-.253 98.052.012ZM20.729 99.337c-8.919 0-15.22-.783-20.729-2.923v23.542c5.572 1.127 11.609 1.625 18.535 1.625 16.089-.151 29.295-3.405 39.618-9.76a22.75 22.75 0 0 0 2.417-1.648c.714.762 1.462 1.49 2.244 2.182 5.79 5.554 13.554 8.935 23.289 10.145h16.777c3.98-.485 7.339-1.321 10.076-2.508 4.457-1.829 7.805-4.63 10.044-8.402V88.261c-.889 4.071-2.504 6.896-4.845 8.475-4.159 2.546-11.283 3.741-21.374 3.586-8.01 0-13.748-1.58-17.215-4.743-3.62-2.853-5.43-6.325-5.43-10.413-.153-9.332 6.778-14.154 20.797-14.463H123v-19.9H90.426c-11.53.14-20.554 2.976-27.062 8.517-6.261-5.343-15.659-8.419-28.18-9.234L12.984 48.61C6.997 48.056 2.67 46.836 0 44.949v24.88a66.78 66.78 0 0 0 6.92.91l23.745 1.817c4.99.15 8.99 1.097 12.002 2.837 2.925 1.664 4.388 4.615 4.388 8.852 0 4.766-2.451 8.473-7.356 11.12-4.903 2.648-11.227 3.972-18.97 3.972ZM180.08 41.82c0 10.08-7.44 16.64-18.88 16.64-10.88 0-19.2-6.48-20.8-16.4l8.56-2.08c.8 6.8 5.52 10.88 12.4 10.88 6.4 0 10.16-3.28 10.16-8.32 0-4.56-3.04-7.2-11.84-10.64-11.52-4.4-15.6-8.56-15.6-16.4 0-8.88 6.72-14.8 16.8-14.8 7.36 0 13.2 3.28 16.64 9.76l-7.12 4.4c-2.08-4.24-5.68-6.56-9.76-6.56-4.64 0-8 3.12-8 6.96 0 4.16 3.04 6.48 11.6 9.76 12.08 4.64 15.84 8.96 15.84 16.8Z"
                fill="#000" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M236.844 29.66c0 17.36-10.64 28.88-26.24 28.88-15.68 0-26.32-11.52-26.32-28.88 0-17.44 10.64-28.96 26.32-28.96s26.24 11.52 26.24 28.96Zm-44 0c0 12.8 6.96 20.72 17.76 20.72s17.68-8 17.68-20.72c0-12.8-6.88-20.8-17.68-20.8s-17.76 7.92-17.76 20.8Z"
                fill="#000" />
            <path
                d="M246.038 57.5h32.88v-7.92h-24.32V1.74h-8.56V57.5ZM322.628 57.5h-36.88V1.74h36.88v7.92h-28.32v15.28h26v7.92h-26v16.72h28.32v7.92Z"
                fill="#000" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m373.363 57.5-15.12-24.72c6.96-2.08 10.72-7.28 10.72-15.2 0-10-5.92-15.84-18.8-15.84h-17.44V57.5h8.48V33.82h8.56l13.6 23.68h10Zm-32.16-31.52V9.66h8.08c7.28 0 11.04 2.16 11.04 8.08s-3.76 8.24-11.04 8.24h-8.08ZM423.766 57.5h-9.36l-4.56-12.64h-22.32l-4.64 12.64h-9.28l21.76-55.76h6.64l21.76 55.76Zm-25.04-43.28-8.4 22.88h16.72l-8.32-22.88ZM180 121.5h9.36L167.6 65.74h-6.64L139.2 121.5h9.28l4.64-12.64h22.32L180 121.5Zm-24.08-20.4 8.4-22.88 8.32 22.88h-16.72ZM211.618 121.5h-15.68V65.74h15.76c18.4 0 28.88 10.24 28.88 28.32 0 17.76-10.16 27.44-28.96 27.44Zm-7.12-47.84v39.92h6.32c14.64 0 21.12-6.08 21.12-19.84 0-13.68-6.48-20.08-20.32-20.08h-7.12Z"
                fill="#000" />
            <path
                d="m282.639 65.74-16 41.92-16.08-41.92h-9.44l22.24 55.76h6.56l22.16-55.76h-9.44ZM307.12 121.5h-8.56V65.74h8.56v55.76ZM335.585 122.46c11.44 0 18.88-6.56 18.88-16.64 0-7.84-3.76-12.16-15.84-16.8-8.56-3.28-11.6-5.6-11.6-9.76 0-3.84 3.36-6.96 8-6.96 4.08 0 7.68 2.32 9.76 6.56l7.12-4.4c-3.44-6.48-9.28-9.76-16.64-9.76-10.08 0-16.8 5.92-16.8 14.8 0 7.84 4.08 12 15.6 16.4 8.8 3.44 11.84 6.08 11.84 10.64 0 5.04-3.76 8.32-10.16 8.32-6.88 0-11.6-4.08-12.4-10.88l-8.56 2.08c1.6 9.92 9.92 16.4 20.8 16.4Z"
                fill="#000" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M411.228 93.66c0 17.36-10.64 28.88-26.24 28.88-15.68 0-26.32-11.52-26.32-28.88 0-17.44 10.64-28.96 26.32-28.96s26.24 11.52 26.24 28.96Zm-44 0c0 12.8 6.96 20.72 17.76 20.72s17.68-8 17.68-20.72c0-12.8-6.88-20.8-17.68-20.8s-17.76 7.92-17.76 20.8ZM461.063 121.5l-15.12-24.72c6.96-2.08 10.72-7.28 10.72-15.2 0-10-5.92-15.84-18.8-15.84h-17.44v55.76h8.48V97.82h8.56l13.6 23.68h10Zm-32.16-31.52V73.66h8.08c7.28 0 11.04 2.16 11.04 8.08s-3.76 8.24-11.04 8.24h-8.08Z"
                fill="#000" />
            <path
                d="M502.421 105.82c0 10.08-7.44 16.64-18.88 16.64-10.88 0-19.2-6.48-20.8-16.4l8.56-2.08c.8 6.8 5.52 10.88 12.4 10.88 6.4 0 10.16-3.28 10.16-8.32 0-4.56-3.04-7.2-11.84-10.64-11.52-4.4-15.6-8.56-15.6-16.4 0-8.88 6.72-14.8 16.8-14.8 7.36 0 13.2 3.28 16.64 9.76l-7.12 4.4c-2.08-4.24-5.68-6.56-9.76-6.56-4.64 0-8 3.12-8 6.96 0 4.16 3.04 6.48 11.6 9.76 12.08 4.64 15.84 8.96 15.84 16.8Z"
                fill="#000" />
        </svg>

export default SA_logo;
