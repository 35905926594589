import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo";
import Navigation from "./Navigation"
import Footer from "./Footer"

const Layout = ({ location, children }) => {

  useEffect(
        () => {
            try {
                const UIkit = require("uikit/dist/js/uikit");
                const Icons = require("uikit/dist/js/uikit-icons");
                UIkit.use(Icons);
            } catch (e) {
                console.error("UIKit ERROR", e);
            }
        }, []
    )

  return (
    <>
        <Navigation />
        <main>
        {children}
        </main>
        {location?.pathname !== "/contact-us/" ?
        <Footer />
        :null}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
