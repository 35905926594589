import { Link } from "gatsby";
import React, {useMemo} from "react";
import { useLocation } from "@reach/router";
import * as style from "./Navigation.module.css";
import SA_logo from "../../images/SA_logo.js";

const OffCanvas = () => {
    const { pathname } = useLocation()
    return (
        <div id="offcanvas-nav-primary" className={`${style.mobileNav} uk-animation-fade uk-animation-fast`}
            data-uk-offcanvas="overlay: false; flip: false; mode: slide; selClose: .uk-close">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-flex-middle">
                <div
                    className={`${style.Logo} uk-logo uk-position-top-left uk-padding-small uk-close`}>
                    <SA_logo color={"Wheat"} />
                </div>
                <button
                    className="uk-position-top-right uk-margin-xsmall-right uk-offcanvas-close uk-active"
                    type="button"
                    data-uk-close />
                <ul
                    className="uk-nav uk-nav-primary uk-margin-auto-vertical uk-text-left uk-width-5-6"
                    data-uk-nav>
                    <li className="uk-margin-large-bottom">
                        <Link className={`${pathname === "/" ? "uk-active uk-close" : "uk-opacity-50"}`} to={`/`}>Home</Link>
                    </li>
                    <li className="uk-margin-large-bottom">
                        <Link
                            className={`${pathname.includes("/sift") ? "uk-active" : "uk-opacity-50"} ${pathname === "/sift" ? "uk-close" : ""}`}
                            to={`/sift/`}>SIFT
                        </Link>
                    </li>
                    <li className="uk-margin-large-bottom">
                        <Link
                            className={`${pathname.includes("/feed") ? "uk-active" : "uk-opacity-50"} ${pathname === "/feed" ? "uk-close" : ""}`}
                            to={`/feed/`}>Feed
                        </Link>
                    </li>
                    <li className="uk-margin-large-bottom">
                        <Link
                            className={`${pathname.includes("/about-us") ? "uk-active" : "uk-opacity-50"} ${pathname === "/about-us" ? "uk-close" : ""}`}
                            to={`/about-us/`}>About
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={`${pathname.includes("/contact-us") ? "uk-active" : "uk-opacity-50"} ${pathname === "/contact-us" ? "uk-close" : ""}`}
                            to={`/contact-us/`}>Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const Navigation = ({ ...props }) => {
    const { pathname } = useLocation()

    return (
        <>
            <div className="uk-position-top">
                <div className={`${style.nav}`} data-uk-sticky="cls-active: uk-background-grape-darker uk-light uk-box-shadow-medium; top: 1vh; animation: uk-animation-fade">
                    <div className="uk-container">
                        <nav className="uk-container uk-navbar uk-navbar-container uk-navbar-transparent">
                            <div className="uk-navbar-left">
                                <div className="uk-navbar-item uk-padding-remove-horizontal">
                                    <Link className={`${style.Logo} uk-logo`} to={`/`}>
                                        <SA_logo color={"Wheat"} />
                                    </Link>
                                </div>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav uk-visible@s">
                                    {pathname !== "/" ?
                                        <li className="uk-text-bold uk-navbar-item"><Link className={`${pathname === "/" ? "uk-active" : ""}`} to={`/`}>Home</Link></li>
                                        : null}
                                    <li className="uk-text-bold uk-navbar-item">
                                        <Link className={`${pathname.includes("/sift") ? "uk-active" : ""}`} to={`/sift/`}>SIFT</Link>
                                    </li>
                                    <li className="uk-text-bold uk-navbar-item">
                                        <Link className={`${pathname.includes("/feed") ? "uk-active" : ""}`} to={`/feed/`}>Feed</Link>
                                    </li>
                                    <li className="uk-text-bold uk-navbar-item">
                                        <Link className={`${pathname.includes("/about-us") ? "uk-active" : ""}`} to={`/about-us/`}>About</Link>
                                    </li>
                                    <li className="uk-margin-left uk-flex uk-flex-middle">
                                        <div>
                                            <Link to={`/contact-us/`} className="uk-button uk-button-wine uk-border-rounded">Arrange a Demo</Link>
                                        </div>
                                    </li>
                                </ul>
                                <button
                                    data-uk-toggle="target: #offcanvas-nav-primary"
                                    className="uk-button-text uk-navbar-toggle uk-navbar-item uk-hidden@s"
                                    data-uk-navbar-toggle-icon />
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <OffCanvas />
        </>
    )
}

export default Navigation;
