import React from "react";
import Contact from "../Contact"
import style from "./Footer.module.css"

const ContactInfo = () =>
    <div className="uk-width-2-5@m uk-flex-first@m">
        <h2 className="h3__large uk-text-primary">Solera Advisors</h2>
        <p className="uk-text-grape-lightest">Enabling decisions through transparency.</p>
        <p className="uk-text-bold uk-text-grape-lightest">
                Chicago | Toronto | London | Kiel
        </p>
        <div className="uk-margin">
            <a href="https://twitter.com/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="twitter" />
            <a href="https://www.facebook.com//" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="facebook" />
            <a href="https://www.linkedin.com/company/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="linkedin" />
        </div>
    </div>

const Footer = () => {
    return (
        <section id="ContactForm" className={`${style.footerContent} footer uk-background-earth-darker`}>
            <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                <div className="uk-width-5-6 uk-width-3-5@m uk-margin-xlarge-top">
                    <div className="uk-grid uk-grid-large uk-light">
                        <ContactInfo />
                        <div className="uk-width-expand uk-flex-first">
                            <Contact />
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-container uk-container-xlarge uk-background-earth-darker">
                <div className="uk-container uk-container-small uk-padding-remove-bottom uk-margin-remove-bottom">
                    <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                        <small>&copy; 2018-{new Date().getFullYear()} <a href="https://soleraadvisors.com">Solera Advisors.</a> All Rights Reserved.</small>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Footer;
